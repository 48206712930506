import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Logo from "../components/coax-logo"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
      <SEO title="Home" />
      <div>
        <div>
          <h1>Hold tight, we are in the process of something GREAT!</h1>
          <Logo/>
        </div>
      </div>
      <Link to="/services/">List Of Services</Link>
    </Layout>
)

export default IndexPage